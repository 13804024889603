import React, { memo, useState } from 'react';

import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';
import MultiValueButton from 'ebo-react-component-library/dist/components/input/button/MultiValueButton';
import { useActionForPerson } from './hooks/useActionForLocation/useActionForPerson';

import './PersonDetailActions.scss';
import { useAuthorizationContext } from '../../../../contexts/AuthorizationContext/AuthorizationContext';
import { Button } from 'ebo-react-component-library/dist/components/input/button/Button';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import { useStaticCallback } from 'ebo-react-component-library/dist/hooks';

// ------------------------------------
// Variables
// ------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-detail-actions');
const blockClassName = getBlockClassName();

const transferToDaughtercraftLabel = translate('person.action.transfer_to_daughtercraft');

// ------------------------------------
// Components
// ------------------------------------

/**
 * @property {Object} props
 * @property {ActionForPerson[]} props.actions
 * @property {boolean} props.canEdit
 */
const DaughterCraftActions = ({ actions, canEdit }) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = useStaticCallback(() => setOpen((open) => !open));

  return (
    <MultiValueButton
      onClick={toggleOpen}
      onIconClicked={toggleOpen}
      isCollapsed={!isOpen}
      label={transferToDaughtercraftLabel}
      onOverlayClicked={toggleOpen}
      color="light-grey"
      isBold={false}
      hasBorderRadius={false}
      className={getElementClassName('daughtercraft-actions__button')}
      overlayClassName={getElementClassName('daughtercraft-actions__button__overlay')}
      placement="bottomRight"
    >
      {actions.map(({ key, label, submitRequest }) => (
        <Button
          key={key}
          id={'personDetailAction'}
          label={label}
          className={getElementClassName('action')}
          isBold={false}
          onClick={submitRequest}
          isDisabled={!canEdit}
        />
      ))}
    </MultiValueButton>
  );
};

/**
 * This component resembles the action a person can make.
 *
 * <p>The action is based on the possible locations. If only one location is available, the
 * request is made immediately. Otherwise a popup will opened to select a location.
 */
const PersonDetailActionsBase = ({ locationOfInterest }) => {
  const { mainVehicleActions, daughtercraftActions } = useActionForPerson(locationOfInterest);
  const { edit } = useAuthorizationContext();

  const hasDaughtercraftActions = daughtercraftActions?.length > 0;
  const hasMainVehicleActions = mainVehicleActions?.length > 0;

  if (!hasMainVehicleActions && !hasDaughtercraftActions) {
    return null;
  }

  return (
    <div className={blockClassName}>
      {hasMainVehicleActions &&
        mainVehicleActions.map(({ key, label, submitRequest }) => (
          <Button
            key={key}
            id={'personDetailAction'}
            label={label}
            className={getElementClassName('action')}
            isBold={false}
            onClick={submitRequest}
            isDisabled={!edit}
          />
        ))}

      {hasDaughtercraftActions && (
        <DaughterCraftActions actions={daughtercraftActions} canEdit={edit} />
      )}
    </div>
  );
};

export const PersonDetailActions = memo(PersonDetailActionsBase);
