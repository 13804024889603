import { useCallback, useMemo } from 'react';

import { translate, translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import {
  useDisembark,
  useEventSubmission,
} from '../../../../../../data/api/hooks/useEventSubmission/useEventSubmission';
import { useSelectedTransferPlanContext } from '../../../../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import { useSelectedPerson } from '../../../../../../contexts/SelectedPersonContext/SelectedPersonContext';
import { useTransferPlanState } from '../../../../../../data/api/hooks/useTransferPlanState/useTransferPlanState';
import { useOptimisticLocationUpdateHandler } from '../useOptimisticLocationUpdateHandler/useOptimisticLocationUpdateHandler';

/**
 * An enumeration of all the possible actions that can be done.
 */
export const Actions = {
  BOARD: 'board',
  TRANSFER_TO_DAUGHTERCRAFT: 'transfer_to_daughtercraft',
  TRANSFER_TO_LOCATION: 'transfer_to_location',
  DISEMBARK: 'disembark',
};

/**
 * @typedef ActionForPerson
 * An object that represents the action that can be taken. This is based on the person's location and the
 * location of interest.
 *
 * @property {String} key Key unique to the action.
 * @property {String|undefined} label The translation for the action related to the location.
 * @property {function(Location): void} submitRequest Submits the actcion to for the given location.
 */
/**
 * @typedef ActionForPersonResult
 *
 * @property {ActionForPerson[]} mainVehicleActions These actions are related to the main transfer vehicle.
 * @property {ActionForPerson[]} daughtercraftActions Actions related to the main vehicle's daughtercraft.
 */
/**
 * A hook that returns the action that can be taken for a location
 *
 * @property {Location} locationOfInterest
 * @return {ActionForPersonResult}
 */
export const useActionForPerson = (locationOfInterest) => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();
  const { selectedPerson, resetSelectedPerson } = useSelectedPerson();
  const { refetch } = useTransferPlanState(selectedTransferPlan?.name);
  const handleOptimisticUpdate = useOptimisticLocationUpdateHandler();

  const { submit: submitDisembark } = useDisembark({
    onSettled: refetch,
    onMutate: handleOptimisticUpdate,
  });
  const { submit } = useEventSubmission({
    onSettled: refetch,
    onMutate: handleOptimisticUpdate,
  });

  const personId = selectedPerson?.personId;
  const transferPlanId = selectedTransferPlan?.id;
  const onTransferPlanVessel = selectedPerson?.currentlyOnTransferPlanVessel;
  const currentLocationId = selectedPerson?.currentLocation?.locationId;
  const locationId = locationOfInterest?.locationId;

  const mapAction = useCallback(
    ({ key, action, toLocation }) => {
      const label = (() => {
        const labelKey = `person.action.${action}`;

        switch (action) {
          case Actions.BOARD:
          case Actions.DISEMBARK:
            return translate(labelKey);
          case Actions.TRANSFER_TO_DAUGHTERCRAFT:
            return toLocation?.name;
          case Actions.TRANSFER_TO_LOCATION:
            return translateFormat(labelKey, {
              location: toLocation?.name,
            });
          default:
            return undefined;
        }
      })();

      const submitRequest = () => {
        const request = {
          toLocation,
          personId: personId,
          transferPlanId: transferPlanId,
          timestamp: new Date().getTime(),
        };

        if (action === Actions.DISEMBARK) {
          submitDisembark([request]);
        } else {
          submit([request]);
        }

        resetSelectedPerson();
      };

      return {
        key,
        label,
        submitRequest,
      };
    },
    [personId, resetSelectedPerson, submit, submitDisembark, transferPlanId]
  );

  return useMemo(() => {
    const actions = getActions(
      selectedTransferPlan,
      onTransferPlanVessel,
      currentLocationId,
      locationId
    );

    return actions.reduce(
      (reducedActions, currentAction) => {
        if (Actions.TRANSFER_TO_DAUGHTERCRAFT === currentAction) {
          reducedActions.daughtercraftActions = reducedActions.daughtercraftActions.concat(
            selectedTransferPlan?.daughtercraft?.map((daughtercraft) =>
              mapAction({
                key: `${currentAction}__${daughtercraft.locationId}`,
                action: currentAction,
                toLocation: daughtercraft,
              })
            )
          );
        } else {
          reducedActions.mainVehicleActions = reducedActions.mainVehicleActions.concat([
            mapAction({
              key: currentAction,
              action: currentAction,
              toLocation: locationOfInterest,
            }),
          ]);
        }

        return reducedActions;
      },
      {
        mainVehicleActions: [],
        daughtercraftActions: [],
      }
    );
  }, [
    currentLocationId,
    locationId,
    locationOfInterest,
    mapAction,
    onTransferPlanVessel,
    selectedTransferPlan,
  ]);
};

/**
 * Returns the action based on the current location and the location the person
 * should go to.
 *
 * <ul>
 *   <li>The go to location is a vehicle and the person currently is on the vehicle: {@link Actions#DISEMBARK}
 *   <li>The go to location is a vehicle and the person is somewhere else: {@link Actions#BOARD}
 *   <li>The go to location is not a vehicle and the person currently is on a vehicle: {@link Actions#DISEMBARK_TO_LOCATION}
 *   <li>The go to location is not a vehicle and the person somewhere else: {@link Actions#BOARD}
 * </ul>
 */
const getActions = (
  transferPlan,
  onMainVehicle,
  currentLocationId,
  locationOfInterestLocationId
) => {
  const { vehicle, daughtercraft } = transferPlan;

  const daughtercraftLocationIds = (daughtercraft || []).map((craft) => craft.locationId);
  const isOnTransferView = locationOfInterestLocationId === vehicle.locationId;
  const actions = [];

  if (isOnTransferView) {
    if (onMainVehicle) {
      actions.push(Actions.DISEMBARK);
    }

    if (onMainVehicle && daughtercraftLocationIds.length > 0) {
      actions.push(Actions.TRANSFER_TO_DAUGHTERCRAFT);
    }

    if (!onMainVehicle) {
      actions.push(Actions.BOARD);
    }

    return actions;
  }

  if (onMainVehicle || daughtercraftLocationIds.includes(currentLocationId)) {
    actions.push(Actions.TRANSFER_TO_LOCATION);
  } else if (currentLocationId === locationOfInterestLocationId) {
    actions.push(Actions.BOARD);
    actions.push(Actions.TRANSFER_TO_DAUGHTERCRAFT);
  }

  return actions;
};
