import './PersonDetail.scss';

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';
import { usePersonImageUri } from '../../../data/api/hooks/useEntityImageUri/usePersonImageUri';

import PersonDetailHeader from './PersonDetailHeader/PersonDetailHeader';
import PersonDetailPictureTypeCompany from './PersonDetailPictureTypeCompany/PersonDetailPictureTypeCompany';
import PersonDetailBody from './PersonDetailBody/PersonDetailBody';

import { personPropTypes } from '../../../model/Person';
import { locationPropTypes } from '../../../model/Location';

// ------------------------------------
// Variables
// ------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-card-detail');

// ------------------------------------
// Components
// ------------------------------------

export const PersonDetailBase = ({ person, locationOfInterest, onClose }) => {
  const personImageUri = usePersonImageUri(person);

  return (
    <div className={getBlockClassName()}>
      <div className={getElementClassName('content')}>
        <PersonDetailHeader
          person={person}
          locationOfInterest={locationOfInterest}
          onClose={onClose}
        />
        <PersonDetailPictureTypeCompany person={person} personImageUrl={personImageUri} />
        <PersonDetailBody person={person} locationOfInterest={locationOfInterest} />
      </div>
      <div className={getElementClassName('overlay')} onClick={onClose} />
    </div>
  );
};

PersonDetailBase.propTypes = {
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes),
  locationOfInterest: PropTypes.shape(locationPropTypes),
  /** The function that determines what should happen when the 'close' icon was clicked inside
  //  *  this component */
  onClose: PropTypes.func,
};

const PersonDetail = memo(PersonDetailBase);
export default PersonDetail;
