import React, { useMemo } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import useGlobalEventsAsToasts from 'raf-core-react/dist/components/app/RafToasts/useGlobalEventsAsToasts/useGlobalEventsAsToasts';

import { BugReporterContextProvider } from 'raf-core-react/dist/contexts/BugReporterContext/BugReporterContext';

import BugReportStorer from 'ebo-react-component-library/dist/components/utils/BugReport/BugReportStorer';

import './ReportableToastMessages.scss';

// ----------------------------------------
// Component
// ----------------------------------------

/**
 * A wrapper component that renders the toast messages generated by the <code>GlobalEvents</code>.
 * These messages include functionality to "report the issue".
 */
export const ReportableToastMessages = ({ children }) => {
  const { keycloak } = useKeycloak();
  useGlobalEventsAsToasts();

  const bugReportStorer = useMemo(
    () =>
      new BugReportStorer('errorStorageKey', 'eventStorageKey', {
        userName: keycloak.tokenParsed.name,
      }),
    [keycloak.tokenParsed.name]
  );

  return (
    <BugReporterContextProvider bugReportStorer={bugReportStorer}>
      {children}
    </BugReporterContextProvider>
  );
};
