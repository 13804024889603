import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { getPersonTypeIconWrapper } from '../../../components/utils/PersonTypeModifiers';
import IconWrapper from '../../../components/image/icon/IconWrapper';
import PersonGroup from '../../../components/person/PersonGroup/PersonGroup';
import LocationsCard from '../../../components/locations/locationscard/LocationsCard';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';
import { useTransferPlanPeopleContext } from '../../../contexts/TransferPlanPeopleContext/TransferPlanPeopleContext';
import { groupedByPersonType } from './util/groupedByPersonType/groupedByPersonType';
import { personPropTypes, PersonTypes } from '../../../model/Person';

// -------------------------------------
// Variables
// -------------------------------------

const { getElementClassName, getBlockClassName } = BEMClassNameUtils('locations');
const locationsVesselsWhereaboutsClassName = getElementClassName('vessels-whereabouts');
const buttonWrapperActiveClass = getElementClassName('button', 'activated');
const buttonWrapperInactiveClass = getElementClassName('button', 'inactivated');
const titleClassName = getElementClassName('title');
const cardsHolder = getElementClassName('holder');

const peopleOnBoard = translate('location.peopleOnBoard');

const initialBoardingStateFilter = [
  PersonTypes.OFFSHORE_WORKER,
  PersonTypes.VEHICLE_CREW,
  PersonTypes.VISITOR,
];

// -------------------------------------
// Component
// -------------------------------------

const PersonOnBoardFilterButton = ({ boardingState, people, isActive, onToggle }) => {
  const handleToggle = useCallback(() => onToggle(boardingState), [onToggle, boardingState]);

  return (
    <div
      key={`button-${boardingState}`}
      data-cy={'locations-view-toggle-button'}
      className={isActive ? buttonWrapperActiveClass : buttonWrapperInactiveClass}
      onClick={handleToggle}
    >
      {getPersonTypeIconWrapper(getBlockClassName(), boardingState)}
      {people.length}
    </div>
  );
};

PersonOnBoardFilterButton.propTypes = {
  boardingState: PropTypes.oneOf(Object.values(PersonTypes)).isRequired,
  people: PropTypes.arrayOf(PropTypes.shape(personPropTypes)).isRequired,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

const PeopleOnBoardBase = () => {
  const { byTransferState } = useTransferPlanPeopleContext();

  /** @type {Object<PersonTypes, PeopleInBoardingState>} */
  const personGroupAboard = useMemo(
    () => byTransferState && groupedByPersonType(byTransferState.ON_TRANSFER),
    [byTransferState]
  );

  const [activeBoardingStateFilters, setActiveBoardingStateFilters] = useState(
    initialBoardingStateFilter
  );
  const [isVisible, setIsVisible] = useState(false);

  /**
   * This function will toggle to show/hide the people of a certain worktype
   */
  const handleGroupButtonToggle = useCallback(
    (toggledBoardingState) => {
      isVisible &&
        setActiveBoardingStateFilters((currentlyActive) =>
          currentlyActive.includes(toggledBoardingState)
            ? currentlyActive.filter((boardingState) => boardingState !== toggledBoardingState)
            : [...currentlyActive, toggledBoardingState]
        );
    },
    [isVisible]
  );

  /**
   * This function resets the hideable view when you collapse it
   */
  const toggleDisplayOfCards = useCallback(() => {
    if (Object.values(personGroupAboard).find(({ people }) => people.length > 0)) {
      setIsVisible(!isVisible);
      if (isVisible) {
        setActiveBoardingStateFilters(initialBoardingStateFilter);
      }
    }
  }, [isVisible, personGroupAboard]);

  return (
    <div className={locationsVesselsWhereaboutsClassName}>
      <div className={getElementClassName('whereabouts-container', isVisible ? 'open' : 'closed')}>
        <div className={titleClassName}>{peopleOnBoard}</div>

        {personGroupAboard &&
          Object.entries(personGroupAboard).map(([boardingState, { people }]) => (
            <PersonOnBoardFilterButton
              key={`pob-filter_${boardingState}`}
              boardingState={boardingState}
              people={people}
              isActive={
                isVisible && activeBoardingStateFilters.includes(boardingState) && people.length > 0
              }
              onToggle={handleGroupButtonToggle}
            />
          ))}

        <IconWrapper
          className={getElementClassName('icon', isVisible ? 'visible' : 'hidden')}
          idPrefix={'locations-group-body'}
          iconName={'dropdown'}
          onClick={toggleDisplayOfCards}
        />
      </div>

      {personGroupAboard && isVisible && (
        <PersonGroup>
          {Object.entries(personGroupAboard)
            .filter(
              ([boardingState, { people }]) =>
                activeBoardingStateFilters.includes(boardingState) && people.length > 0
            )
            .flatMap(([_, { people }]) => people)
            .map((person) => (
              <div className={cardsHolder} key={`person-mini-card-${person.personId}`}>
                <LocationsCard person={person} />
              </div>
            ))}
        </PersonGroup>
      )}
    </div>
  );
};

export const PeopleOnBoard = memo(PeopleOnBoardBase);
