import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import { useSelectedTransferPlanContext } from '../SelectedTransferPlanContext/SelectedTransferPlanContext';
import { Topics, useTopic } from '../TopicContext/TopicContext';
import { useQueryClient } from 'react-query';

import { getAllPersonsFromTransferPlan } from '../../utils/getAllPersonsFromTransferPlan/getAllPersonsFromTransferPlan';
import { getGroupedPersonsByTransferState } from '../../utils/getGroupedPersonsByTransferState/getGroupedPersonsByTransferState';
import { getGroupedPersonsByType } from '../../utils/getGroupedPersonsByType/getGroupedPersonsByType';
import { useTransferPlanAutomaticLoggedEvents } from '../../data/api/hooks/useLoggedEvents/useLoggedEvents';
import { isPersonTracked } from '../../components/person/hooks/usePersonIsTracked/usePersonIsTracked';
import { usePeopleNearVehicle } from '../../data/api/hooks/usePeopleNearVehicle/usePeopleNearVehicle';

// -------------------------------------------------
// Variables
// -------------------------------------------------

/**
 * @typedef TransferPlanPersonsContextResult
 * An object holding the people of the transfer. Either in a flat list or grouped.
 *
 * @property {Array<Person>} all All people on the transfer
 * @property {Object<TransferState,Array<Person>>} byTransferState People grouped by their state
 * @property {Object<PersonType,Array<Person>>} byPersonType People grouped by their state
 */
/**
 * @type {React.Context<TransferPlanPersonsContextResult>}
 */
export const TransferPlanPeopleContext = createContext(undefined);
TransferPlanPeopleContext.displayName = 'TransferPlanPeopleContext';

const empty = {
  all: undefined,
  byTransferState: undefined,
  byPersonType: undefined,
};

// -------------------------------------------------
// Provider
// -------------------------------------------------

/**
 * Provides the transfer plan state to it's consumers.
 *
 * @see TransferPlanPeopleContext
 */
export const TransferPlanPeopleContextProvider = ({ children }) => {
  const { selectedTransferPlan, transferPlanNumber } = useSelectedTransferPlanContext();
  const { data: transferPlanAutomaticLoggedEvents } = useTransferPlanAutomaticLoggedEvents(
    selectedTransferPlan?.id
  );
  const { addListener } = useTopic(Topics.LOCATION);
  const queryClient = useQueryClient();
  const { data: peopleNearVehicle } = usePeopleNearVehicle();

  const isPersonTrackedForTransferPlan = useCallback(
    ({ personId }) =>
      !!(
        transferPlanAutomaticLoggedEvents &&
        transferPlanAutomaticLoggedEvents[personId] &&
        isPersonTracked(transferPlanAutomaticLoggedEvents[personId])
      ),
    [transferPlanAutomaticLoggedEvents]
  );

  const transferPlanPeople = useMemo(() => {
    if (!selectedTransferPlan) {
      return empty;
    }

    const transferPlanPersons = getAllPersonsFromTransferPlan({
      transferPlan: selectedTransferPlan,
      peopleNearVehicle,
    });
    const peopleByTransferState = getGroupedPersonsByTransferState(
      selectedTransferPlan,
      transferPlanPersons,
      isPersonTrackedForTransferPlan
    );
    const peopleByType = getGroupedPersonsByType(transferPlanPersons);

    return {
      all: transferPlanPersons,
      byTransferState: peopleByTransferState,
      byPersonType: peopleByType,
    };
  }, [peopleNearVehicle, isPersonTrackedForTransferPlan, selectedTransferPlan]);

  const refetchTransferPlan = useCallback(
    (id) => {
      const personInvolved = transferPlanPeople.all.find((person) => person.personId === id);
      if (personInvolved) {
        queryClient.refetchQueries(['transferPlan', transferPlanNumber]);
      }
    },
    [queryClient, transferPlanPeople, transferPlanNumber]
  );

  useEffect(() => {
    const listenToLocationEvents = ({ method, params }) => {
      if ('locationEvents.updatePersonLocations' === method) {
        refetchTransferPlan(params.id);
      }
    };

    const removeListener = addListener(listenToLocationEvents);
    return () => removeListener();
  }, [addListener, refetchTransferPlan]);

  return (
    <TransferPlanPeopleContext.Provider value={transferPlanPeople}>
      {children}
    </TransferPlanPeopleContext.Provider>
  );
};

// -------------------------------------------------
// Hook
// -------------------------------------------------

/**
 * A hook that returns the transfer plan persons.
 *
 * @throws {Error} When no provider is found.
 * @return TransferPlanPersonsContextResult
 */
export const useTransferPlanPeopleContext = () => {
  const transferPlanStateContextValue = useContext(TransferPlanPeopleContext);

  if (!transferPlanStateContextValue) {
    throw Error('Wrap the component with a TransferPlanPeopleContextProvider');
  }

  return transferPlanStateContextValue;
};

/**
 * @typedef OnOrOffVehicle
 * A small object that has a list of people on the current transfer plan vehicle,
 * or not.
 *
 * @property {Array<Person>} on People who are on the vehicle
 * @property {Array<Person>} off People who are not on the vehicle
 */
/**
 * @typedef TransferPlanPeopleWithLocation
 *
 * @property {Array<Person>} withLocation People who are seen at any location
 * @property {OnOrOffVehicle} onOrOffVehicle Holder for people who are on or off the vehicle
 * @property {boolean} noneOnALocationOrVehicle Indicates if nobody is on a location or on the current vehicle
 * @property {boolean} allOnVehicle Indicates if everyone with a location is on the current vehicle
 * @property {boolean} allOnALocation Indicates if everyone with a location is on a location that is not the current vehicle
 * @property {boolean} peopleScattered People with a location are both on the vehicle but also somewhere else.
 */
/**
 * A hook that holds information about people with a certain location.
 *
 * @return TransferPlanPeopleWithLocation
 */
export const useTransferPlanPeopleWithLocation = () => {
  const { all } = useTransferPlanPeopleContext();

  const withLocation = all && all.filter((person) => !!person.currentLocation);

  /**
   * @type {OnOrOffVehicle}
   */
  const onOrOffVehicle = useMemo(() => {
    if (!withLocation) {
      return null;
    }

    return withLocation.reduce(
      (onOrOffVehicle, person) => {
        if (person.currentlyOnTransferPlanVessel) {
          onOrOffVehicle.on.push(person);
        } else {
          onOrOffVehicle.off.push(person);
        }

        return onOrOffVehicle;
      },
      {
        on: [],
        off: [],
      }
    );
  }, [withLocation]);

  if (!onOrOffVehicle) {
    return {
      withLocation,
      onOrOffVehicle,
      noneOnALocationOrVehicle: undefined,
      allOnVehicle: undefined,
      allOnALocation: undefined,
      peopleScattered: undefined,
    };
  }

  const noneOnALocationOrVehicle = withLocation.length === 0;
  const allOnVehicle = onOrOffVehicle.on.length > 0 && onOrOffVehicle.off.length === 0;
  const allOnALocation = onOrOffVehicle.on.length === 0 && onOrOffVehicle.off.length > 0;
  const peopleScattered = onOrOffVehicle.on.length > 0 && onOrOffVehicle.off.length > 0;

  return {
    withLocation,
    onOrOffVehicle,
    noneOnALocationOrVehicle,
    allOnVehicle,
    allOnALocation,
    peopleScattered,
  };
};
