import React, { memo } from 'react';

import IconWrapper from '../../image/icon/IconWrapper';

import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';

import './ConnectionIndicators.scss';
import { useVehicleGatewayStatus } from '../../../contexts/VehicleGatewayStatusContext/VehicleGatewayStatusContext';
import { useApiStatus } from '../../../contexts/ApiStatusContext/ApiStatusContext';
import { useGatewayStatusBemModifiers } from '../../../data/api/hooks/useGatewayStatus/useGatewayStatus';

//-----------------------------------------------------
// Variables
//-----------------------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('connection-indicators');

//-----------------------------------------------------
// Components
//-----------------------------------------------------

const Gateway = () => {
  const gatewayStatus = useVehicleGatewayStatus();
  const modifiers = useGatewayStatusBemModifiers(gatewayStatus);

  return (
    <div
      data-testid={'connection-indicator-gateway'}
      className={getElementClassName('gateway', modifiers)}
    >
      <IconWrapper idPrefix={'header-controls-gateway'} iconName={'gateway'} />
    </div>
  );
};

const ApiSignal = () => {
  const apiStatus = useApiStatus();
  const modifiers = useGatewayStatusBemModifiers(apiStatus);

  return (
    <div
      data-testid={'connection-indicator-signal'}
      className={getElementClassName('signal', modifiers)}
    >
      <IconWrapper idPrefix={'header-controls-signal-status'} iconName={'signal'} />
    </div>
  );
};

const ConnectionIndicatorsBase = () => (
  <div data-testid={'connection-indicators'} className={getBlockClassName()}>
    <Gateway />
    <ApiSignal connectionLost />
  </div>
);

const ConnectionIndicators = memo(ConnectionIndicatorsBase);

export { ConnectionIndicators };
