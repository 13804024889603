import './PersonDetailBodyLocation.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { translate, translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import {
  calculateMillisPassedFromNow,
  convertMillisecondsToReadableString,
} from '../../../../utils/TrackingTimeUtils';
import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';

const { getBlockClassName, getElementClassName } = BEMClassNameUtils(
  'person-card-detail-body-location'
);

const locationUnknown = translate('location.unknown');
const unknownTimeAgo = translate('timeAgoUnknown');

export default function PersonDetailBodyLocation(props) {
  const timeAgo = convertMillisecondsToReadableString(
    calculateMillisPassedFromNow(props.locationTrackingEventTimestampMs)
  );

  return (
    <div className={getBlockClassName()}>
      <div className={getElementClassName('name')}>
        {props.locationName ? props.locationName : locationUnknown}
      </div>
      <div className={getElementClassName('time-ago')}>
        {props.locationTrackingEventTimestampMs
          ? translateFormat('timeAgo', { timeAgo: timeAgo })
          : unknownTimeAgo}
      </div>
    </div>
  );
}

PersonDetailBodyLocation.propTypes = {
  /** @link locationPropTypes.ms */
  locationTrackingEventTimestampMs: PropTypes.number,
  /** @link locationPropTypes.name*/
  locationName: PropTypes.string,
};
