import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from './loadingAnimation.json';
import './LoadingAsOverlay.scss';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import PropTypes from 'prop-types';

const loading = translate('loading');

const LoadingAsOverlay = (props) => (
  <div className="loading-as-overlay-wrapper">
    <Lottie
      title={loading}
      className="loading-page-svg"
      loop
      autoplay
      animationData={loadingAnimation}
    />
    {props.messageTranslated && (
      <span className="loading-as-overlay-message">{props.messageTranslated}</span>
    )}
  </div>
);

LoadingAsOverlay.prototype = {
  /** A string that will be used as message underneath the animation. */
  messageTranslated: PropTypes.string,
};

export default LoadingAsOverlay;
