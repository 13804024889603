import React, { memo, useCallback } from 'react';

import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';

import IconWrapper from '../../image/icon/IconWrapper';
import { useTransferPlanSelectionPopup } from '../../../contexts/TransferPlanSelectionPopupContext/TransferPlanSelectionPopupContext';

import './SwitchTransferPlan.scss';
import { useDisembarkAllConfirmationContext } from '../DisembarkAllConfirmationPopup/context/DisembarkAllConfirmationContext';

// ---------------------------------------------
// Variables
// ---------------------------------------------

const { getElementClassName } = BEMClassNameUtils('switch-transfer-plan');

// ---------------------------------------------
// Components
// ---------------------------------------------

const SwitchTransferPlanBase = () => {
  const { openOrExecute } = useDisembarkAllConfirmationContext();
  const { showPopup } = useTransferPlanSelectionPopup();

  const handleSwitchTransferPlan = useCallback(() => {
    openOrExecute(showPopup);
  }, [openOrExecute, showPopup]);

  return (
    <div
      data-testid={'switch-transfer-plan-transfer'}
      className={getElementClassName('icon-wrapper', 'transfer')}
      onClick={handleSwitchTransferPlan}
    >
      <IconWrapper idPrefix={'header'} iconName={'exchange-alt'} />
    </div>
  );
};

export const SwitchTransferPlan = memo(SwitchTransferPlanBase);
