import React, { useCallback } from 'react';
import { useDisembarkAllConfirmationContext } from '../../../components/header/DisembarkAllConfirmationPopup/context/DisembarkAllConfirmationContext';
import { useSelectedTransferPlanContext } from '../../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import { useAuthorizationContext } from '../../../contexts/AuthorizationContext/AuthorizationContext';
import { Button } from 'ebo-react-component-library/dist/components/input/button/Button';
import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';

//////////////////////////////////////////////
// Variables
//////////////////////////////////////////////

const { getBlockClassName } = BEMClassNameUtils('disembark-all');

const disembarkAllLabel = translate('person.action.disembark_all.title');

//////////////////////////////////////////////
// Component
//////////////////////////////////////////////

const DisembarkAll = () => {
  const { openOrExecute, canExecute } = useDisembarkAllConfirmationContext();
  const { reload } = useSelectedTransferPlanContext();
  const { edit } = useAuthorizationContext();

  const handleClick = useCallback(() => {
    openOrExecute(reload);
  }, [openOrExecute, reload]);

  if (!canExecute) {
    return null;
  }

  return (
    <Button
      id={'disembarkAll'}
      label={disembarkAllLabel}
      className={getBlockClassName()}
      isBold={false}
      onClick={handleClick}
      isDisabled={!edit}
    />
  );
};

//////////////////////////////////////////////

export { DisembarkAll as default };
