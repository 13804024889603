import { usePostRequest } from '../useAxiosRequests';
import { noop } from 'ebo-react-component-library/dist/utils/noop';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable-next-line no-unused-vars */
import { MutationOptions, UseMutationResult } from 'react-query';

//---------------------------------------------------------
// Variables
//---------------------------------------------------------

const REQUEST_CLASS = 'com.ebo.omp.endpoint.http.rest.TrackingEventsExtension$TrackingEventRequest';
const LOCATION_CLASS = 'com.ebo.omp.endpoint.http.rest.dto.location.LocationDto';

/**
 * Small factory function that creates a "mutate" function but using only the correct
 * properties from the given variable.
 */
const createSubmitHandler = (mutationFunction, hasLocation = true) => (requests = [], options) => {
  const parsedRequests = requests.map(
    ({ toLocation = {}, personId, transferPlanId, atLocationId = [], timestamp }) => {
      // The API returns errors if the _class_ is not present.
      const location = {
        _class_: LOCATION_CLASS,
        ...toLocation,
      };
      const atLocations = atLocationId.map((location) => ({
        _class_: LOCATION_CLASS,
        ...location,
      }));

      const parsedRequest = {
        _class_: REQUEST_CLASS,
        personId,
        transferPlanId,
        atLocationId: atLocations,
        timestamp,
      };

      if (hasLocation) {
        parsedRequest.toLocation = location;
      }

      return parsedRequest;
    }
  );

  return mutationFunction(parsedRequests, options);
};

//---------------------------------------------------------
// Hooks
//---------------------------------------------------------

/**
 * @typedef Location
 * A small object that represents a location. This object contains minimal
 * information about it's subject.
 *
 * @property {String} id The fabricated ID of the location, contains the type
 * @property {String} name The location's name.
 * @property {Number} lastUpdateMillis The timestamp of in millis indicating when the tracking event took place.
 */
/**
 * @typedef TrackingEventRequest
 * Request for submitting a TransferPlanExecutionEvent. This request is used
 * to update the mentioned person's location.
 *
 * @property {Location} [toLocation] Towards is this person going. If this option is not given, the API
 * assumes that you're not interested in this person anymore. He'll be put "underway" or "onshore".
 * @property {number} personId The database ID of the person
 * @property {number} transferPlanId The database ID of the transfer plan
 * @property {number} timestamp When did the person go to the location
 */
/**
 * @typedef UseEventSubmissionResult
 * This type is somewhat to aide the user into using correct variables when
 * submitting an event to the API.
 *
 * @property {function(Array<TrackingEventRequest>): void} submit
 * @property {function(Array<TrackingEventRequest>): void} submitAsync
 */
/**
 * <p>This hook gives the possibility to submit events. The result of this hook is
 * a combination of the actual "react-query" result and the {@link UseEventSubmissionResult}.</p>
 *
 * <p>It's recommended to use the "submit" variants to upload the events. They use only
 * the required properties of the variable and it reads better. See the examples below.
 *
 * @example
 * const {
 *    submit,
 * } = useEventSubmission();
 * ...
 * submit(request);
 *
 * const {
 *    mutate,
 * } = useEventSubmission();
 * ...
 * mutate(request);
 *
 * @property {Object} factoryParams
 * @property {String} factoryParams.url The url to submit events to.
 * @property {boolean} factoryParams.hasLocation Flag indicating if the request should have a location.
 * @property {MutationOptions?} options
 * @return {UseEventSubmissionResult & UseMutationResult}
 */
const useTrackingEvents = ({ url, hasLocation = true }, options) => {
  const mutationResult = usePostRequest({
    url,
    options,
  });

  return {
    ...mutationResult,
    submit: createSubmitHandler(mutationResult.mutate, hasLocation),
    submitAsync: createSubmitHandler(mutationResult.mutateAsync, hasLocation),
  };
};
/**
 * @see useTrackingEvents
 *
 * @property {MutationOptions.onSuccess} onSuccess Function that is executed when the mutation succeeds.
 * @property {MutationOptions.onError} onError Handle the error that is thrown.
 * @property {MutationOptions.onSettled} onSettled Function whenever either an error occurs or the mutation succeeds.
 * @property {MutationOptions.onMutate} onMutate Function whenever a mutate is called.
 * @return {UseEventSubmissionResult & UseMutationResult}
 */
export const useEventSubmission = ({
  onSuccess = noop,
  onError = noop,
  onSettled = noop,
  onMutate = noop,
} = {}) =>
  useTrackingEvents(
    {
      url: '/omp-service-tracking/events/submit',
    },
    {
      onSuccess,
      onError,
      onSettled,
      onMutate,
    }
  );

/**
 * This hook differs a bit from the {@link useEventSubmission}. Even though we're also submitting events,
 * here we explicitly say to the API to disembark the person. This means that we're not interested anymore
 * in where he is.
 * Technically the {@link TrackingEventRequest#toLocation} is left empty here. The API should put the person
 * on a suitable location.
 *
 * @see useTrackingEvents
 *
 * @property {MutationOptions.onSuccess} onSuccess Function that is executed when the mutation succeeds.
 * @property {MutationOptions.onError} onError Handle the error that is thrown.
 * @property {MutationOptions.onSettled} onSettled Function whenever either an error occurs or the mutation succeeds.
 * @property {MutationOptions.onMutate} onMutate Function whenever a mutate is called.
 * @return {UseEventSubmissionResult & UseMutationResult}
 */
export const useDisembark = ({
  onSuccess = noop,
  onError = noop,
  onSettled = noop,
  onMutate = noop,
} = {}) =>
  useTrackingEvents(
    {
      url: '/omp-service-tracking/events/disembark',
      hasLocation: false,
    },
    {
      onSuccess,
      onError,
      onSettled,
      onMutate,
    }
  );
