import React from 'react';

import IconWrapper from '../../../image/icon/IconWrapper';
import { getPersonTypeIconWrapper } from '../../../utils/PersonTypeModifiers';
import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';
import { useNextFutureMovement } from '../../hooks/useLatestTransferPlanMovement/useNextFutureMovement';
import { translateFormat } from 'raf-core-react/dist/utils/localization/Translations';

import './PersonIconBar.scss';

// ----------------------------------------------
// Variables
// ----------------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-icon-bar');

// ----------------------------------------------
// Component
// ----------------------------------------------

const PersonMovement = ({ movements }) => {
  const movement = useNextFutureMovement(movements);

  if (!movement) {
    return null;
  }

  const joinOrLeave = `${movement.joinOrLeave}`.toLowerCase();
  const title = translateFormat(`person.movement.${joinOrLeave}`, {
    location: movement.locationName,
  });

  return (
    <div className={getElementClassName('person-movement')} title={title}>
      <IconWrapper idPrefix={'person-movement'} iconName={joinOrLeave} />
    </div>
  );
};

const PersonIconBar = ({ person }) => {
  if (!(person.type || person.teams || person.permits)) {
    return null;
  }

  return (
    <div className={getBlockClassName()}>
      {person.type && (
        <div className={getElementClassName('person-type')}>
          {getPersonTypeIconWrapper(getBlockClassName(), person.type)}
        </div>
      )}

      <PersonMovement movements={person.movements} />

      {person.teams && person.teams.length !== 0 && (
        <IconWrapper idPrefix={'person-card-body'} iconName={'user-friends'} />
      )}
      {person.permits && person.permits.length !== 0 && (
        <IconWrapper idPrefix={'person-card-body'} iconName={'file'} />
      )}
    </div>
  );
};

export { PersonIconBar };
