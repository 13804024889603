import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useGetRequest } from '../useAxiosRequests';
import { Topics, useTopic } from '../../../../contexts/TopicContext/TopicContext';
import { getOrDefaultStaleTime } from '../../config';

// -------------------------------------------------------
// Types for documentation
// -------------------------------------------------------

/* eslint-disable-next-line no-unused-vars */
import { UseQueryResult } from 'react-query';

// ----------------------------------------
// Variables
// ----------------------------------------

/**
 * The state of the transfer plan doesn't really change that much. Keep it for
 * half a day.
 *
 * @type {number}
 */
const CACHE_TIME = getOrDefaultStaleTime(1000 * 60 * 60 * 12);

//---------------------------------------------------------
// Hooks
//---------------------------------------------------------

/**
 * Get the transfer plan data of the given number.
 *
 * @param {String} number The number of the transfer plan
 * @param {boolean} autoExecute Flag indicating the query can be executed automatically. If false,
 * the user must {@link UseQueryResult.refetch} in order to retrieve data.
 * @return {UseQueryResult}
 */
export const useTransferPlanState = (number, autoExecute = true) => {
  const queryKey = useMemo(() => ['transferPlan', number], [number]);

  const queryClient = useQueryClient();
  const queryResult = useGetRequest({
    queryKey: queryKey,
    url: `/omp-service-tracking/transfer-plan/state/${number}`,
    options: {
      enabled: !!number && autoExecute,
      staleTime: CACHE_TIME,
      // Don't return the entire response, the data is enough.
      select: ({ data }) => data,
    },
  });

  const { addListener } = useTopic(Topics.TRANSFER_PLAN);

  useEffect(() => {
    const updateCurrentTransferPlanState = ({ params: { id } }) => {
      if (queryResult?.data?.id === id) {
        queryClient.invalidateQueries(queryKey).then(() => {
          return queryResult.refetch();
        });
      }
    };

    const removeListener = addListener(updateCurrentTransferPlanState);
    return () => removeListener();
  }, [addListener, queryClient, queryKey, queryResult]);

  return queryResult;
};

/**
 * Creates a handler to reload the transfer plan matching the given number.
 *
 * @param {String} number The number of the transfer plan
 * @return {{reload: (function(): Promise<void>)}}
 */
export const useReloadTransferPlanState = (number) => {
  const queryKey = useMemo(() => ['transferPlan', number], [number]);
  const queryClient = useQueryClient();

  const reload = useCallback(() => queryClient.refetchQueries(queryKey), [queryClient, queryKey]);

  return {
    reload,
  };
};
