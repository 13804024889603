import './PersonDetailHeader.scss';

import React from 'react';
import IconWrapper from '../../../image/icon/IconWrapper';
import PropTypes from 'prop-types';
import { personPropTypes } from '../../../../model/Person';
import { getPersonBoardingState } from '../../utils/PersonUtils';
import { translateFormat } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';
import { locationPropTypes } from '../../../../model/Location';
import { usePersonPlannedForLocation } from '../../../../utils/hooks/usePersonPlannedForLocation/usePersonPlannedForLocation';

const { getElementClassName } = BEMClassNameUtils('person-card-detail');

export default function PersonDetailHeader(props) {
  const isPlanned = usePersonPlannedForLocation({
    person: props.person,
    location: props.locationOfInterest,
  });

  const personBoardingState = getPersonBoardingState(
    props.locationOfInterest,
    props.person,
    isPlanned
  );

  return (
    <div className={getElementClassName('header', personBoardingState)} onClick={props.onClose}>
      <div className={getElementClassName('header-title')}>
        <div className={getElementClassName('header-person-name')}>{props.person.name}</div>
        {props.person.nok && (
          <div className={getElementClassName('header-person-nok')}>
            {translateFormat('person.nok', { number: props.person.nok })}
          </div>
        )}
      </div>
      <IconWrapper
        idPrefix={getElementClassName('header')}
        iconName={'times'}
        onClick={props.onClose}
      />
    </div>
  );
}

PersonDetailHeader.propTypes = {
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes),
  locationOfInterest: PropTypes.shape(locationPropTypes),
  /** The function that determines what should happen when the 'close' icon was clicked inside
   *  this component */
  onClose: PropTypes.func,
};
