import React, { useMemo } from 'react';
import Popup from 'ebo-react-component-library/dist/components/containers/Popup/Popup';

import CancelButton from 'raf-core-react/dist/components/forms/buttons/CancelButton/CancelButton';
import ConfirmButton from 'raf-core-react/dist/components/forms/buttons/ConfirmButton/ConfirmButton';
import Button from 'ebo-react-component-library/dist/components/input/button/Button';

import { useDisembarkAllConfirmationContext } from './context/DisembarkAllConfirmationContext';
import { useDisembarkAllMessage } from './context/DisembarkAllMessageContext';

import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';

import './DisembarkAllConfirmationPopup.scss';
// ---------------------------------------------
// Variables
// ---------------------------------------------

const componentRootName = 'disembark-all-confirmation';
const { getElementClassName, getBlockClassName } = BEMClassNameUtils(componentRootName);

const disembarkAllLabel = translate('person.action.disembark_all.title');
const yesLabel = translate('yes');
const noLabel = translate('no');

// ---------------------------------------------
// Component
// ---------------------------------------------

export const DisembarkAllConfirmationPopup = () => {
  const {
    hidden,
    closePopup,
    afterDisembarking,
    disembarkAll,
  } = useDisembarkAllConfirmationContext();

  const message = useDisembarkAllMessage();

  const actionButtons = useMemo(
    () =>
      [
        <CancelButton
          key={componentRootName + '__cancel'}
          idPrefix={componentRootName}
          handleCancel={closePopup}
        />,
        afterDisembarking && (
          <Button
            idPrefix={componentRootName}
            key="no"
            label={noLabel}
            id={componentRootName.concat('-no')}
            onClick={afterDisembarking}
            hasBorder={false}
            isBold={false}
            color={'light-grey'}
          />
        ),
        afterDisembarking && (
          <ConfirmButton
            key={componentRootName + '__confirm'}
            idPrefix={componentRootName}
            label={yesLabel}
            handleConfirm={disembarkAll}
          />
        ),
      ].filter((button) => !!button),
    [afterDisembarking, closePopup, disembarkAll]
  );

  return (
    <Popup
      customClassName={getBlockClassName()}
      title={disembarkAllLabel}
      isHidden={hidden}
      portalParentSelector={'body'}
      actionsButtons={actionButtons}
      hasOverlay
      hasBg
      isFullScreenOnMobile
    >
      <div className={getElementClassName('are-you-sure')}>{message}</div>
    </Popup>
  );
};
