import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

import { noop } from 'ebo-react-component-library/dist/utils/noop';

import { useDisembarkAllOnTransfer } from '../../../../utils/hooks/useDisembarkAllOnTransfer/useDisembarkAllOnTransfer';

// ---------------------------------------------
// Variables / functions
// ---------------------------------------------

/**
 * @typedef DisembarkAllConfirmationContextValue
 * Information needed by the disembark all confirmation popup.
 *
 * @property {boolean} hidden Is the popup hidden or not.
 * @property {boolean} canExecute Indicates if the "disembark all" can be executed.
 * @property {function(): void} closePopup Close the confirmation popup.
 * @property {function(function(): void): void} openOrExecute Open the popup or execute the "afterDisembarking" immediately when no one can be disembarked.
 * @property {function(): void} disembarkAll The function that disembarks all the people.
 * @property {function(): void} afterDisembarking The function that should be executed after
 * disembarking all the people.
 */
/**
 * @type {React.Context<DisembarkAllConfirmationContextValue>}
 */
const DisembarkAllConfirmationContext = createContext(undefined);
DisembarkAllConfirmationContext.displayName = 'DisembarkAllConfirmationContext';

// ---------------------------------------------
// Provider
// ---------------------------------------------

/**
 * Provides the {@link DisembarkAllConfirmationContextValue}.
 */
export const DisembarkAllConfirmationContextProvider = ({ children }) => {
  const [hidden, setHidden] = useState(true);
  const afterDisembarkFn = useRef(noop);

  const closePopup = useCallback(() => {
    setHidden(true);
    afterDisembarkFn.current = noop;
  }, []);

  const afterDisembarking = useCallback(() => {
    setHidden(true);
    afterDisembarkFn.current && afterDisembarkFn.current();

    afterDisembarkFn.current = noop;
  }, []);

  const { canExecute, execute: disembarkAll } = useDisembarkAllOnTransfer({
    onSettled: afterDisembarking,
  });

  const openOrExecute = useCallback(
    (afterDisembark) => {
      afterDisembarkFn.current = afterDisembark;

      if (!canExecute) {
        afterDisembarking();
        return;
      }

      setHidden(false);
    },
    [afterDisembarking, canExecute]
  );

  return (
    <DisembarkAllConfirmationContext.Provider
      value={{
        hidden,
        canExecute,
        closePopup,
        openOrExecute,
        disembarkAll,
        afterDisembarking,
      }}
    >
      {children}
    </DisembarkAllConfirmationContext.Provider>
  );
};

// ---------------------------------------------
// Consumer
// ---------------------------------------------

/**
 * Consume the {@link DisembarkAllConfirmationContextValue}.
 */
export const useDisembarkAllConfirmationContext = () => useContext(DisembarkAllConfirmationContext);
