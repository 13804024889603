import './PersonDetailPictureTypeCompany.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { getPersonTypeIconWrapper } from '../../../utils/PersonTypeModifiers';
import TrackingStatusIndicator from '../../../trackingStatusIndicator/TrackingStatusIndicator';
import { getCurrentOrPreviousTimestampFromPersonObject } from '../../../utils/TrackingTimeUtils';
import { personPropTypes } from '../../../../model/Person';
import { translate } from 'raf-core-react/dist/utils/localization/Translations';
import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';

const { getBlockClassName, getElementClassName } = BEMClassNameUtils(
  'person-card-detail-type-picture-company'
);

export default function PersonDetailPictureTypeCompany(props) {
  const iconDiameter = 130,
    iconStroke = 15;

  return (
    <div className={getBlockClassName()}>
      <div className={getElementClassName('picture-and-tracking')}>
        <img
          src={props.personImageUrl}
          className={getElementClassName('picture')}
          alt={props.person.name}
          style={imageDimensions(iconDiameter, iconStroke)}
        />
        <TrackingStatusIndicator
          locationTrackingEventTimestampMs={getCurrentOrPreviousTimestampFromPersonObject(
            props.person
          )}
          iconDiameter={150}
          iconStroke={15}
          dashArray={377}
        />
      </div>
      {props.person.company && (
        <div className={getElementClassName('company')}>{props.person.company.name}</div>
      )}
      {props.person.type && (
        <div className={getElementClassName('person-type', props.person.type)}>
          {getPersonTypeIconWrapper(getBlockClassName(), props.person.type)}
          <div>{translate('person.type.' + props.person.type)}</div>
        </div>
      )}
    </div>
  );
}

const imageDimensions = (diameter, stroke) => {
  return {
    width: diameter - 3 * stroke + 21,
    height: diameter - 3 * stroke + 21,
  };
};

PersonDetailPictureTypeCompany.propTypes = {
  /** @link personPropTypes */
  person: PropTypes.shape(personPropTypes),
  /** The url that leads to the person's profile picture. */
  personImageUrl: PropTypes.string,
};
