import React, { memo } from 'react';

import { GlobalZIndexContextProvider } from 'ebo-react-component-library/dist/contexts/GlobalZIndexContext/GlobalZIndexContext';

import { TransferPlanListOfTodayContextProvider } from './TransferPlanListOfTodayContext/TransferPlanListOfTodayContext';
import { SelectedTransferPlanContextProvider } from './SelectedTransferPlanContext/SelectedTransferPlanContext';
import { TransferPlanPeopleContextProvider } from './TransferPlanPeopleContext/TransferPlanPeopleContext';
import { TransferPlanSelectionPopupContextProvider } from './TransferPlanSelectionPopupContext/TransferPlanSelectionPopupContext';
import { ApiStatusContextProvider } from './ApiStatusContext/ApiStatusContext';
import { VehicleGatewayStatusContextProvider } from './VehicleGatewayStatusContext/VehicleGatewayStatusContext';
import { PersonWhereaboutsContextProvider } from './PersonWhereaboutsContext/PersonWhereaboutsContext';
import { WebSocketContextProvider } from './WebSocketContext/WebSocketContext';
import { config } from '../data/api/config';
import { TopicContextProvider } from './TopicContext/TopicContext';
import { WebSocketListenersContextProvider } from './WebSocketContext/WebSocketListenersContext';
import { TrackingApplicationWideErrorContextProvider } from './TrackingApplicationWideErrorContext/TrackingApplicationWideErrorContext';
import { AuthorizationContextProvider } from './AuthorizationContext/AuthorizationContext';

//-----------------------------------------------------
// Component
//-----------------------------------------------------

const TransferPlanRelatedContexts = ({ children }) => (
  <TransferPlanListOfTodayContextProvider>
    <SelectedTransferPlanContextProvider>
      <TransferPlanSelectionPopupContextProvider>
        {children}
      </TransferPlanSelectionPopupContextProvider>
    </SelectedTransferPlanContextProvider>
  </TransferPlanListOfTodayContextProvider>
);

const PersonRelatedContexts = ({ children }) => (
  <TransferPlanPeopleContextProvider>
    <PersonWhereaboutsContextProvider>{children}</PersonWhereaboutsContextProvider>
  </TransferPlanPeopleContextProvider>
);

const WebSocketContexts = ({ children }) => (
  <WebSocketContextProvider url={config.websocket.url}>
    <WebSocketListenersContextProvider>
      <TopicContextProvider>{children}</TopicContextProvider>
    </WebSocketListenersContextProvider>
  </WebSocketContextProvider>
);

/**
 * A collection of the different contexts used in the application.
 */
const AppContexts = ({ children }) => (
  <GlobalZIndexContextProvider>
    <WebSocketContexts>
      <AuthorizationContextProvider>
        <ApiStatusContextProvider>
          <TrackingApplicationWideErrorContextProvider>
            <TransferPlanRelatedContexts>
              <VehicleGatewayStatusContextProvider>
                <PersonRelatedContexts>{children}</PersonRelatedContexts>
              </VehicleGatewayStatusContextProvider>
            </TransferPlanRelatedContexts>
          </TrackingApplicationWideErrorContextProvider>
        </ApiStatusContextProvider>
      </AuthorizationContextProvider>
    </WebSocketContexts>
  </GlobalZIndexContextProvider>
);

const MemoAppContexts = memo(AppContexts);

// Users of this component don't care if it's memoized or not.
//
// Preferring not to use default export as users can easily give another name
// to the variable when importing, without explicitly saying so.
// (so not using the keyword 'as')
export { MemoAppContexts as AppContexts };
