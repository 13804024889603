import React, { memo } from 'react';

import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';

import './PersonGroup.scss';

// ---------------------------------------
// Variables
// ---------------------------------------

const { getBlockClassName, getElementClassName } = BEMClassNameUtils('person-group');

// ---------------------------------------
// Component
// ---------------------------------------

const PersonGroupBase = ({ title, actions, children }) => (
  <div className={getBlockClassName()}>
    {title && (
      <h2 className={getElementClassName('title')}>
        {title}
        {actions && <div className={getElementClassName('actions')}>{actions}</div>}
      </h2>
    )}
    <div className={getElementClassName('content')}>{children}</div>
  </div>
);

export default memo(PersonGroupBase);
