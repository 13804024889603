import { createGetMethod } from 'raf-core-react/dist/utils/axios/axiosUtils';
import { config } from '../config';

export const logout = async () => {
  await createGetMethod(`${config.rest.url}/user/logout`, {
    headers: { Authorization: config.headers.Authorization },
  })();

  document.location.href = '/';
};
