import React, { memo } from 'react';
import './Locations.scss';
import { useSelectedTransferPlanContext } from '../../contexts/SelectedTransferPlanContext/SelectedTransferPlanContext';
import LocationsPeopleWhereabouts from '../../components/locations/LocationsPeopleWhereabouts/LocationsPeopleWhereabouts';

import BEMClassNameUtils from 'ebo-react-component-library/dist/utils/style/BEMClassNameUtils/BEMClassNameUtils';
import { PeopleOnBoard } from './PeopleOnBoard/PeopleOnBoard';

const { getElementClassName } = BEMClassNameUtils('locations');
const locationsGridClassName = getElementClassName('grid');

/**
 * This is the component for the vessel whereabouts view
 * it is fairly similar to the person component in code
 */
const LocationsBase = () => {
  const { selectedTransferPlan } = useSelectedTransferPlanContext();

  return (
    <div className={locationsGridClassName}>
      <PeopleOnBoard />
      {selectedTransferPlan && <LocationsPeopleWhereabouts />}
    </div>
  );
};

const Locations = memo(LocationsBase);
export default Locations;
